<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <v-row dense>
      <v-col cols="3">
        <scheduled-service-card></scheduled-service-card>
        <!-- <component-selector></component-selector> -->
      </v-col>
      <v-col cols="9">
        <component-builder></component-builder>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// const ComponentSelector = () => import("../builder/ComponentSelector.vue");
const ComponentBuilder = () => import("../builder/ComponentBuilder.vue");
const ScheduledServiceCard = () => import("../cards/ScheduledServiceCard.vue");

export default {
  components: {
    // ComponentSelector,
    ComponentBuilder,
    ScheduledServiceCard
  }
};
</script>
